import logo from 'assets/logo-light-1.svg'
import Alert from 'components/Alert'
import Loader from 'components/Loader'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import getUploadUrl from 'lib/getUploadUrl'
import parseLocationSearch from 'lib/parseLocationSearch'
import { getHomeRoute } from 'lib/routes'
import toHumanDate from 'lib/toHumanDate'
import toHumanPropertyStatus from 'lib/toHumanPropertyStatus'
import toMoney from 'lib/toMoney'
import useQuery from 'lib/useQuery'
import { HaveQuestions, SegmentHere } from 'pages/DashboardPage'
import { Link as NativeLink, useLocation } from 'react-router-dom'
import css from './styles.module.scss'

const Property = ({ property, isFuture, isFinished, isActive }) => {
  return (
    <div className={css.item}>
      <a className={css.clickable} rel="noreferrer" target="_blank" href={property.notionUrl}>
        <div className={css.photo}>
          <img
            className={css.photoImage}
            width="662"
            height="496"
            alt=""
            src={getUploadUrl(property.photo, 'property')}
          />
        </div>
        <div className={css.title}>{property.name}</div>
        <div className={css.info}>
          <div className={css.infoItem}>
            <span>Инвест. предложение</span> №{property.serialNumber}
          </div>
          {!isFinished && (
            <div className={css.infoItem}>
              <span>Доходность</span> {property.roi}
              {/* <span>ROI</span> {propertyWithMoney.property.minProfitPercent} – 
            {propertyWithMoney.property.maxProfitPercent}% */}
            </div>
          )}
          <div className={css.infoItem}>
            <span>Статус</span> {toHumanPropertyStatus(property.status)}
          </div>
          {!!property.neededToCollectOriginal && (
            <div className={css.infoItem}>
              <span>Сумма сбора</span> {toMoney(property.neededToCollectOriginal, property.collectCurrency)}
            </div>
          )}
          {!!property.leftToCollectOriginal && (
            <div className={css.infoItem}>
              <span>Осталось собрать</span> {toMoney(property.leftToCollectOriginal, property.collectCurrency)}
            </div>
          )}
          {!isFuture && (
            <div className={css.infoItem}>
              <span>Дата старта</span> {toHumanDate(property.startsAt, 'D MMM YYYY')}
            </div>
          )}
          {isFinished && (
            <div className={css.infoItem}>
              <span>Дата завершения</span> {toHumanDate(property.endsAt, 'D MMM YYYY')}
            </div>
          )}
          {isFinished && (
            <>
              <div className={css.infoItem}>
                <span>Доходность проекта</span> {property.roi}
                {/* <span>ROI проекта</span> {propertyWithMoney.property.profitPercentTotal}% (
              {propertyWithMoney.property.realProfitPercent}% в годовых) */}
              </div>
              <div className={css.infoItem}>
                <span>Ваша доходность</span> {property.roi}
                {/* <span>Ваш ROI</span> {propertyWithMoney.profitPercentTotal}% ({propertyWithMoney.profitPercentYear}% в
              годовых) */}
              </div>
            </>
          )}
        </div>
      </a>
    </div>
  )
}

const PropertiesList = ({ me }) => {
  const { properties, loading, error } = useQuery('getProperties', {
    fetchPolicy: 'network-only',
  })
  if (loading) return <Loader type="section" />
  if (error) return <Alert type="danger" message={error.humanMessage} />
  if (!properties.length) return <Alert message="Нет объектов" />
  return (
    <SegmentHere title="Все объекты" id="future">
      {!properties?.length ? (
        <Alert compact message="На данный момент нет объектов" />
      ) : (
        <div className={css.properties}>
          {properties.map((property) => (
            <Property key={property.name} isFuture={true} property={property} />
          ))}
        </div>
      )}
    </SegmentHere>
  )
}

const AllPropertiesPage = ({ me }) => {
  const location = useLocation()
  const parsedLocationSearch = parseLocationSearch(location.search)
  const referralCode = parsedLocationSearch.r
  return (
    <PageWrapper>
      <div className={css.layout}>
        <div className={css.header}>
          <div className={css.logoPlace}>
            <img src={logo} alt="Estate Invest" />
          </div>
        </div>
        <div className={css.content}>
          <Segment className={css.wrapper}>
            {/* canManageProperties(me) && (
            <div className={css.new}>
              <Buttons>
                <Button to={getPropertyCreateRoute()}>Добавить объект</Button>
              </Buttons>
            </div>
          ) */}
            <PropertiesList />
            <HaveQuestions />
            {!me && (
              <SegmentHere title="Заполните анкету" id="help">
                <div className={css.questions}>
                  <p className={css.intro}>
                    Чтобы начать процесс инвестирования, заполните анкету. После заполнения анкеты вы получите доступ к
                    личному кабинету, а также с вами свяжется наш менеджер для того, чтобы ответить на все ваши вопросы
                  </p>
                  <NativeLink
                    rel="noreferrer"
                    className={css.button}
                    to={`${getHomeRoute()}${referralCode ? '?r=' + referralCode : ''}`}
                    target="_blank"
                  >
                    <span className={css.buttonText}>Заполнить анкету</span>
                  </NativeLink>
                </div>
              </SegmentHere>
            )}
          </Segment>
        </div>
      </div>
    </PageWrapper>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper redirerctUnauthorized>{({ me }) => <AllPropertiesPage me={me} {...props} />}</PageWrapper>
)

export default CheckAuthWrapper
