import Alert from 'components/Alert'
import Button from 'components/Button'
import Form, { FormItems, useForm } from 'components/Form'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { canSyncSheets } from 'lib/can'
import useMutation from 'lib/useMutation'

const SyncSheetsPage = ({ user, me }) => {
  const [syncSheets] = useMutation('syncSheets')
  const form1 = useForm({
    onSubmit: () => {
      return syncSheets()
    },
    successMessage: 'Данные начали синхронизироваться',
  })

  const [whatSheets] = useMutation('whatSheets')
  const form2 = useForm({
    onSubmit: () => {
      return whatSheets()
    },
    successMessage: (result) => {
      return result.data.whatSheets
    },
  })
  return (
    <Segment title={`Синхронизация с гугл таблицами`}>
      <Form formik={form1.formik}>
        <FormItems>
          <Alert message="Синхронизация происходит автоматически каждую ночь. На этой странице вы можете вручную запустить синхронизацию и увидеть, какие есть ошибки в данных таблиц, если таковые имеются" />
          <Alert {...form1.alertProps} />
          <Alert {...form2.alertProps} />
          <Button {...form1.buttonProps}>Синхронизировать</Button>
          <Button {...form2.buttonProps} type="button" onClick={() => form2.formik.handleSubmit()}>Как там?</Button>
        </FormItems>
      </Form>
    </Segment>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canSyncSheets(me)}>
    {() => <SyncSheetsPage {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
