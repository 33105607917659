import ApolloProvider from 'components/ApolloProvider'
import AuthLayout from 'components/AuthLayout/index'
import ClearLayout from 'components/ClearLayout'
import DashboardLayout from 'components/DashboardLayout'
import DocsLayout from 'components/DocsLayout/index.js'
import { NotAuthRouteTracker } from 'components/NotAuthRouteTracker/index.js'
import { MyToaster } from 'components/Notifications/index.js'
import ReferralLink from 'components/ReferralLink'
import ScrollTopRouter from 'components/ScrollTopRouter'
import SectionWrapper from 'components/SectionWrapper'
import UserMixpanel from 'components/UserMixpanel'
import * as routes from 'lib/routes'
import AllPropertiesPage from 'pages/AllPropertiesPage/index.js'
import BankAccountsPage from 'pages/BankAccountsPage'
import ConfirmEmailPage from 'pages/ConfirmEmailPage'
import ConfirmNewEmailPage from 'pages/ConfirmNewEmailPage'
import DashboardPage from 'pages/DashboardPage'
import DocsContractPage from 'pages/DocsContractPage/index.js'
import DocsPolicyPage from 'pages/DocsPolicyPage/index.js'
import DocsTermsPage from 'pages/DocsTermsPage/index.js'
import DummyReferralCreatePage from 'pages/DummyReferralCreatePage/index.js'
import EditProfilePage from 'pages/EditProfilePage'
import FaqPage from 'pages/FaqPage/index.js'
import GenDocPage from 'pages/GenDocPage/index.js'
import LastSignUpsAndTxsPage from 'pages/LastSignUpsAndTxsPage/index.js'
import NewPaymentPage from 'pages/NewPaymentPage/index.js'
import NewsPage from 'pages/NewsPage/index.js'
import NewsPublicPage from 'pages/NewsPublicPage/index.js'
import NotFoundPage from 'pages/NotFoundPage'
import { NotionFuturePropertiesPage } from 'pages/NotionFuturePropertiesPage/index.js'
import { NotionPropertiesPage } from 'pages/NotionPropertiesPage/index.js'
import PassportsPage from 'pages/PassportsPage'
import PromoPage from 'pages/PromoPage'
import PropertiesPage from 'pages/PropertiesPage/index.js'
import PropertyCreatePage from 'pages/PropertyCreatePage/index.js'
import PropertyEditPage from 'pages/PropertyEditPage/index.js'
import PropertyPage from 'pages/PropertyPage/index.js'
import PublicPropertiesPage from 'pages/PublicPropertiesPage/index.js'
import { RedirectPage } from 'pages/RedirectPage/index.js'
import ReferralsPage from 'pages/ReferralsPage'
import ResetPasswordPage from 'pages/ResetPasswordPage'
import RestorePasswordPage from 'pages/RestorePasswordPage'
import { ResultsPage } from 'pages/ResultsPage/index.js'
import SetupBotPage from 'pages/SetupBotPage/index.js'
import SignInPage from 'pages/SignInPage'
import SignUpPage from 'pages/SignUpPage'
import SyncSheetsPage from 'pages/SyncSheetsPage/index.js'
import SyncTbankrotPage from 'pages/SyncTbankrotPage/index.js'
import UserCreatePage from 'pages/UserCreatePage'
import UserEditPage from 'pages/UserEditPage'
import UserPage from 'pages/UserPage'
import UsersPage from 'pages/UsersPage'
import WelcomePage from 'pages/WelcomePage'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import 'styles/global.scss'
import AppContextProvider from './components/AppContextProvider/index.js'
import BankAccountPage from './pages/BankAccountPage/index.js'
import PassportPage from './pages/PassportPage/index.js'
import CompanyResultsAdminPage from 'pages/CompanyResultsAdminPage/index.js'
import CompanyResultCreatePage from 'pages/CompanyResultCreatePage/index.js'
import CompanyResultEditPage from 'pages/CompanyResultEditPage/index.js'
import CompanyResultsPage from 'pages/CompanyResultsPage/index.js'

function App() {
  return (
    <ApolloProvider>
      <AppContextProvider>
        <Router>
          <NotAuthRouteTracker />
          <ScrollTopRouter />
          <UserMixpanel />
          <ReferralLink />
          <MyToaster />
          <Switch>
            <Route
              path={routes.getDashboardRoute()}
              render={() => (
                <SectionWrapper queryName={'getMe'}>
                  {({ me }) => (
                    <DashboardLayout>
                      <Switch>
                        <Route exact path={routes.getDashboardRoute()} component={DashboardPage} />
                        <Route exact path={routes.getPropertiesRoute()} component={PropertiesPage} />
                        <Route exact path={routes.getAllPropertiesRoute()} component={AllPropertiesPage} />
                        <Route exact path={routes.getNewsRoute()} component={NewsPage} />
                        <Route exact path={routes.getLastSignUpsAndTxsRoute()} component={LastSignUpsAndTxsPage} />

                        <Route exact path={routes.getCompanyResultsAdminRoute()} component={CompanyResultsAdminPage} />
                        <Route exact path={routes.getCompanyResultCreateRoute()} component={CompanyResultCreatePage} />
                        <Route
                          exact
                          path={routes.getCompanyResultEditRoute(':companyResultSerialNumber')}
                          component={CompanyResultEditPage}
                        />

                        <Route exact path={routes.getPropertyCreateRoute()} component={PropertyCreatePage} />
                        <Route exact path={routes.getPropertyRoute(':propertySerialNumber')} component={PropertyPage} />
                        <Route
                          exact
                          path={routes.getPropertyEditRoute(':propertySerialNumber')}
                          component={PropertyEditPage}
                        />
                        <Route exact path={routes.getGenDocRoute()} component={GenDocPage} />
                        <Route exact path={routes.getWelcomeRoute()} component={WelcomePage} />
                        <Route exact path={routes.getEditProfileRoute()} component={EditProfilePage} />
                        <Route exact path={routes.getSetupBotRoute()} component={SetupBotPage} />
                        <Route exact path={routes.getReferralsRoute()} component={ReferralsPage} />
                        <Route exact path={routes.getUsersRoute()} component={UsersPage} />
                        <Route exact path={routes.getUserCreateRoute()} component={UserCreatePage} />
                        <Route exact path={routes.getUserRoute(':userSerialNumber')} component={UserPage} />
                        <Route exact path={routes.getUserEditRoute(':userSerialNumber')} component={UserEditPage} />
                        <Route exact path={routes.getPassportsRoute()} component={PassportsPage} />
                        <Route exact path={routes.getPassportRoute(':passportId')} component={PassportPage} />
                        <Route exact path={routes.getBankAccountsRoute()} component={BankAccountsPage} />
                        <Route exact path={routes.getBankAccountRoute(':bankAccountId')} component={BankAccountPage} />
                        <Route exact path={routes.getSyncSheetsRoute()} component={SyncSheetsPage} />
                        <Route exact path={routes.getSyncTbankrotRoute()} component={SyncTbankrotPage} />
                        <Route exact path={routes.getFaqRoute()} component={FaqPage} />
                        <Route exact path={routes.getNewPaymentRoute()} component={NewPaymentPage} />
                        <Route render={() => <NotFoundPage />} />
                      </Switch>
                    </DashboardLayout>
                  )}
                </SectionWrapper>
              )}
            />
            <Route
              path={routes.getDocsRoute()}
              render={() => (
                <DocsLayout>
                  <Switch>
                    <Route exact path={routes.getDocsPolicyRoute()} component={DocsPolicyPage} />
                    <Route exact path={routes.getDocsTermsRoute()} component={DocsTermsPage} />
                    <Route exact path={routes.getDocsContractRoute()} component={DocsContractPage} />
                    <Route render={() => <NotFoundPage />} />
                  </Switch>
                </DocsLayout>
              )}
            />
            <Route
              path={routes.getNewsPublicRoute()}
              render={() => (
                <DocsLayout>
                  <Switch>
                    <Route exact path={routes.getNewsPublicRoute()} component={NewsPublicPage} />
                    <Route render={() => <NotFoundPage />} />
                  </Switch>
                </DocsLayout>
              )}
            />
            <Route
              path={[
                routes.getConfirmEmailRoute(':confirmEmailToken'),
                routes.getConfirmNewEmailRoute(':confirmNewEmailToken'),
              ]}
              render={() => (
                <ClearLayout>
                  <Switch>
                    <Route
                      exact
                      path={routes.getConfirmEmailRoute(':confirmEmailToken')}
                      component={ConfirmEmailPage}
                    />
                    <Route
                      exact
                      path={routes.getConfirmNewEmailRoute(':confirmNewEmailToken')}
                      component={ConfirmNewEmailPage}
                    />
                  </Switch>
                </ClearLayout>
              )}
            />
            <Route
              path={[
                routes.getSignInRoute(),
                routes.getSignUpRoute(),
                routes.getRestorePasswordRoute(),
                routes.getResetPasswordRoute(':passwordResetToken'),
              ]}
              render={() => (
                <AuthLayout>
                  <Switch>
                    <Route exact path={routes.getSignInRoute()} component={SignInPage} />
                    <Route exact path={routes.getSignUpRoute()} component={SignUpPage} />
                    <Route exact path={routes.getRestorePasswordRoute()} component={RestorePasswordPage} />
                    <Route
                      exact
                      path={routes.getResetPasswordRoute(':passwordResetToken')}
                      component={ResetPasswordPage}
                    />
                    <Route render={() => <NotFoundPage />} />
                  </Switch>
                </AuthLayout>
              )}
            />
            <Route exact path={routes.getHomeRoute()} component={PromoPage} />
            <Route exact path={routes.getCompanyResultsRoute()} component={CompanyResultsPage} />
            <Route
              render={() => (
                <ClearLayout>
                  <Switch>
                    <Route exact path={routes.getPublicPropertiesRoute()} component={PublicPropertiesPage} />
                    <Route exact path={routes.getNotionPropertiesRoute()} component={NotionPropertiesPage} />
                    <Route exact path={routes.getResultsRoute()} component={ResultsPage} />
                    <Route
                      exact
                      path={'/balance'}
                      component={() => (
                        <RedirectPage
                          to={'https://estateinvest.online/dashboard?modal=new-payment&propertySerialNumber=null&sum='}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={'/portfolio'}
                      component={() => <RedirectPage to={'https://estateinvest.online/dashboard#portfolio'} />}
                    />
                    <Route
                      exact
                      path={routes.getNotionFuturePropertiesRoute()}
                      component={NotionFuturePropertiesPage}
                    />
                    <Route exact path={routes.getCreateDummyReferralRoute()} component={DummyReferralCreatePage} />
                    <Route render={() => <NotFoundPage />} />
                  </Switch>
                </ClearLayout>
              )}
            />
          </Switch>
        </Router>
      </AppContextProvider>
    </ApolloProvider>
  )
}

export default App
