import cn from 'classnames'
import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import Icon from 'components/Icon'
import Link from 'components/Link'
import Loader from 'components/Loader'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { canManageProperties } from 'lib/can'
import { getPropertyEditRoute, getPropertyRoute } from 'lib/routes'
import toMoney from 'lib/toMoney'
import useDropdown from 'lib/useDropdown'
import useQuery from 'lib/useQuery'
import { useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useHistory } from 'react-router-dom'
import css from './styles.module.scss'
import toHumanDate from 'lib/toHumanDate'

const LastSignUpsAndTxs = ({ me }) => {
  const { users, txs, loading, error } = useQuery('getLastSignUpsAndTxs', {
    fetchPolicy: 'network-only',
  })
  if (loading) return <Loader type="section" />
  if (error) return <Alert type="danger" message={error.humanMessage} />
  return (
    <div className={css.wrapper}>
      <Segment size={2} title="Регистрации">
        <div className={css.list}>
          {users.map((user) => (
            <div key={user.id} className={css.item}>
              <div className={css.property}>
                <Link color="text-white" to={`/dashboard/users/${user.serialNumber}`}>
                  {user.name}
                </Link>
              </div>
              <div className={css.property}>Дата регистрации: {toHumanDate(user.createdAt, 'DD.MM.YYYY HH:mm:ss')}</div>
            </div>
          ))}
        </div>
      </Segment>
      <Segment size={2} title="Транзакции">
        <div className={css.list}>
          {txs.map((tx) => (
            <div key={tx.id} className={css.item}>
              <div className={css.property}>
                Пользователь:{' '}
                <Link color="text-white" to={`/dashboard/users/${tx.user.serialNumber}`}>
                  {tx.user.name}
                </Link>
              </div>
              <div className={css.property}>Дата транзакции: {toHumanDate(tx.createdAt, 'DD.MM.YYYY')}</div>
              <div className={css.property}>
                Тип транзакции:{' '}
                {
                  {
                    depositInitialIncome: 'Пополнение баланса',
                    depositBonus: 'Подарочный бонус',
                    // depositAdditionalIncome: 'Довложение в депозит',
                    depositConvertation: 'Конвертация в депозите',
                    depositOutcome: 'Вывод из депозита',
                    investmentOutcome: `Вложение в объект №${tx.property?.serialNumber}`,
                    // investmentIncome: `Возврат инвестиции с объекта №${tx.property?.serialNumber}`,
                    // payoutIncome: `Доход с объекта №${tx.property?.serialNumber} (в депозит)`,
                    // payoutOutcome: `Доход с объекта №${tx.property?.serialNumber} (на руки)`,
                    payoutBody: `Выплата тела с объекта №${tx.property?.serialNumber}`,
                    payoutInterest: `Выплата процента с объекта №${tx.property?.serialNumber}`,
                    payoutReferral: `Выплата реферального бонуса`,
                    payoutTeam: `Выплата командного бонуса`,
                  }[tx.type]
                }
              </div>
              <div className={css.property}>Сумма транзакции: {toMoney(tx.sum, tx.currency)}</div>
              {!!tx.property && (
                <div className={css.property}>
                  Объект:{' '}
                  <Link color="text-white" to={`/dashboard/properties/${tx.property.serialNumber}`}>
                    {tx.property.name}
                  </Link>
                </div>
              )}
            </div>
          ))}
        </div>
      </Segment>
    </div>
  )
}

const LastSignUpsAndTxsPage = () => {
  return (
    <PageWrapper redirerctUnauthorized>
      {({ me }) => (
        <Segment title="Последние пользователи и транзакции">
          {/* canManageProperties(me) && (
            <div className={css.new}>
              <Buttons>
                <Button to={getPropertyCreateRoute()}>Добавить объект</Button>
              </Buttons>
            </div>
          ) */}
          <LastSignUpsAndTxs me={me} />
        </Segment>
      )}
    </PageWrapper>
  )
}

export default LastSignUpsAndTxsPage
