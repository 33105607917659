import cn from 'classnames'
import Alert from 'components/Alert'
import PageWrapper from 'components/PageWrapper'
import toHumanDate from 'lib/toHumanDate'
import useQuery from 'lib/useQuery'
import { useState } from 'react'
import css from './styles.module.scss'

const getNewsByDates = (newsPosts, isMy) => {
  const allPosts = newsPosts.filter((newsPost) => (isMy === null ? true : newsPost.isMy === isMy))
  const dates = allPosts.map((newsPost) => newsPost.date)
  const uniqueDates = dates.filter((date, i) => dates.indexOf(date) === i)
  const newsByDates = []
  for (const date of uniqueDates) {
    const news = allPosts.filter((newsPost) => newsPost.date === date)
    newsByDates.push({ date: new Date(date), news })
  }
  return newsByDates
}

export const NewsList = ({ newsPosts, withLink, withPropertyName, withDate }) => {
  return (
    <div className={css.news}>
      {newsPosts.map((newsPost, i) => (
        <div className={css.newsPost} key={i}>
          {withDate && <h3 className={css.newsPostDate}>{toHumanDate(newsPost.date, 'D MMMM YYYY')}</h3>}
          {withPropertyName && <h3 className={css.title}>{newsPost.property.name}</h3>}
          <p className={css.text}>{newsPost.text}</p>
          {withLink && (
            <p className={css.linkPlace}>
              <a href={newsPost.url}>Подробнее</a>
            </p>
          )}
        </div>
      ))}
    </div>
  )
}

export const NewsPage = ({ newsPosts, withSeparation = true }) => {
  const [isMy, setIsMy] = useState(true)
  const myNewsByDates = getNewsByDates(newsPosts, true)
  const otherNewsByDates = getNewsByDates(newsPosts, false)
  const newsByDates = withSeparation ? (isMy ? myNewsByDates : otherNewsByDates) : getNewsByDates(newsPosts, null)
  return (
    <div className={css.wrapper}>
      {!!withSeparation && (
        <div className={css.switcher}>
          <button onClick={() => setIsMy(true)} className={cn({ [css.switcherItem]: true, [css.active]: isMy })}>
            Мои объекты
          </button>
          <button onClick={() => setIsMy(false)} className={cn({ [css.switcherItem]: true, [css.active]: !isMy })}>
            Остальные
          </button>
        </div>
      )}
      {!!newsByDates.length ? (
        <div className={css.newsAndDates}>
          {newsByDates.map((newsByDate, i) => (
            <div className={css.newsAndDate}>
              <div className={css.date}>{toHumanDate(newsByDate.date, 'D MMMM YYYY')}</div>
              <NewsList newsPosts={newsByDate.news} withPropertyName withLink />
            </div>
          ))}
        </div>
      ) : (
        <Alert message="В этом разделе пока нет новостей" />
      )}
    </div>
  )
}

const GetNewsWrapper = (props) => {
  const queryResult = useQuery('getNewsPosts', {
    fetchPolicy: 'network-only',
  })
  return (
    <PageWrapper queryResult={queryResult}>
      {() => <NewsPage newsPosts={queryResult.newsPosts} {...props} />}
    </PageWrapper>
  )
}

export default GetNewsWrapper
