import { useApolloClient } from '@apollo/client'
import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import Form, { FormItems, useForm } from 'components/Form'
import Input from 'components/Input'
import Link from 'components/Link'
import { lastVisistedNotAuthRouteStore } from 'components/NotAuthRouteTracker'
import PageWrapper from 'components/PageWrapper'
import Cookies from 'js-cookie'
import { getRestorePasswordRoute } from 'lib/routes'
import useMutation from 'lib/useMutation'
import yup from 'lib/validation'
import mixpanel from 'mixpanel-browser'

const SignInPage = ({ history }) => {
  const apolloClient = useApolloClient()
  const [signIn] = useMutation('signIn')
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      method: 'email',
      email: '',
      phone: '',
      password: '',
    },
    onSubmit: ({ email, phone, password, companyAlias, method }) => {
      return signIn({
        variables: { input: { email, phone, password, companyAlias } },
        errorCodes: {
          ERR_UNAUTHORIZED: method === 'email' ? 'Неверная почта или пароль' : 'Неверный телефон или пароль',
        },
      }).then(({ token, user }) => {
        if (token) {
          if (process.env.REACT_APP_MIXPANEL_ON === 'true') {
            mixpanel.identify(user.id)
          }
          Cookies.set('token', token, { expires: 99999 })
          return apolloClient.resetStore().then(() => {
            history.push(lastVisistedNotAuthRouteStore)
            return false
          })
        }
      })
    },
    validationSchema: yup.object({
      email: yup.string().when('method', {
        is: 'email',
        then: yup.string().email().required(),
      }),
      phone: yup.string().when('method', {
        is: 'phone',
        then: yup.string().required(),
      }),
      password: yup.string().required(),
    }),
    successMessage: false,
  })
  return (
    <PageWrapper redirerctAuthorized>
      <Form formik={formik}>
        <FormItems>
          {/*<RadioButtons label="Способ входа" direction="column" formik={formik} name="method">
            <RadioButton label="По номеру телефона" value="phone" formik={formik} name="method" />
            <RadioButton label="По адресу почты" value="email" formik={formik} name="method" />
  </RadioButtons>*/}
          {formik.values.method === 'phone' && (
            <Input
              placeholder="Номер телефона"
              name="phone"
              mask="+{7} 000 000-00-00"
              inputMode="tel"
              icon="phone"
              formik={formik}
            />
          )}
          {formik.values.method === 'email' && (
            <Input placeholder="Электронная почта" name="email" inputMode="email" icon="mail" formik={formik} />
          )}
          <Input placeholder="Пароль" type="password" name="password" icon="lock" formik={formik} />
          <Alert {...alertProps} />
          <Buttons>
            <Button {...buttonProps}>Войти</Button>
            <Link color="text-trietary" to={getRestorePasswordRoute()}>
              Восстановить пароль
            </Link>
          </Buttons>
        </FormItems>
      </Form>
    </PageWrapper>
  )
}

export default SignInPage
