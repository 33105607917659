import { gql } from '@apollo/client'
import { propertyFragment } from './propertyFragment.js'

export const getPropertyQuery = gql`
  ${propertyFragment}
  query ($input: GetPropertyInput) {
    getProperty(input: $input) {
      property {
        ...propertyFragment
      }
      newsPosts {
        date
        text
        url
        property {
          id
          name
        }
      }
    }
  }
`

export const getPropertyOptions = {
  parser: [
    ['property', 'getProperty.property'],
    ['newsPosts', 'getProperty.newsPosts'],
  ],
}
