import Button, { Buttons } from 'components/Button'
import Link from 'components/Link'
import PageWrapper from 'components/PageWrapper'
import RichText from 'components/RichText'
import Segment from 'components/Segment'
import { canManageProperties } from 'lib/can'
import { getPropertyEditRoute } from 'lib/routes'
import toHumanDate from 'lib/toHumanDate'
import toHumanPropertyStatus from 'lib/toHumanPropertyStatus'
import toMoney from 'lib/toMoney'
import useQuery from 'lib/useQuery'
import css from './styles.module.scss'
import getUploadUrl from 'lib/getUploadUrl'
import React from 'react'
import { NewsList } from 'pages/NewsPage'

const PropertyViewPage = ({ property, newsPosts, me }) => {
  return (
    <Segment title={`Объект ${property.name}`}>
      {/* property.deletedAt && (
        <div className={css.deletedAlert}>
          <Alert message="Пользователь удалён и не может пользоваться системой" type="danger" />
        </div>
      ) */}
      <RichText>
        <p>
          <img src={getUploadUrl(property.photo, 'property')} alt="" />
        </p>
        {/* <h1>Основное</h1> */}
        <p>
          Название: {property.name || '—'}
          <br />
          Краткое описание: {property.desc || '—'}
          <br />
          Тип недвижимости: {property.propertyType || '—'}
          <br />
          Площадь: {property.area || '—'}
          <br />
          Дополнительные траты:{' '}
          {property.additionalExpenses ? toMoney(property.additionalExpenses, property.sellingCurrency) : '—'}
          <br />
          Цена покупки: {property.purchasePrice ? toMoney(property.purchasePrice, property.sellingCurrency) : '—'}
          <br />
          Цена продажи: {property.sellingPrice ? toMoney(property.sellingPrice, property.sellingCurrency) : '—'}
          <br />
          Минимальный срок реализации в месяцах: {property.minPeriod || '—'}
          <br />
          Максимальный срок реализации в месяцах: {property.maxPeriod || '—'}
          <br />
          Доходность: {property.minProfitPercent} – {property.maxProfitPercent}%
          <br />
          Дата старта объекта: {property.startsAt ? toHumanDate(property.startsAt) : '—'}
          <br />
          Дата завершения объекта: {property.endsAt ? toHumanDate(property.endsAt) : '—'}
          <br />
          Статус: {toHumanPropertyStatus(property.status)}
          <br />
          Сумма сбора: {toMoney(property.neededToCollectOriginal, property.collectCurrency)}
          <br />
          Осталось собрать: {toMoney(property.leftToCollectOriginal, property.collectCurrency)}
          {/* {!!property.notionUrl && (
            <>
              <br />
              Notion:{' '}
              <Link color="system-links-blue" target="_blank" href={property.notionUrl}>
                {property.notionUrl}
              </Link>
            </>
          )} */}
        </p>
      </RichText>
      {!!property.contentHtml && (
        <>
          <br />
          <br />
          <RichText>
            <h1 style={{ marginBottom: 5 }}>Об объекте</h1>
          </RichText>
          <RichText html={property.contentHtml} />
        </>
      )}
      {canManageProperties(me) && (
        <div className={css.edit}>
          <Buttons>
            <Button to={getPropertyEditRoute(property.serialNumber)}>Редактировать</Button>
          </Buttons>
        </div>
      )}
      {!!newsPosts.length && (
        <>
          <br />
          <br />
          <RichText>
            <h1 style={{ marginBottom: 5 }}>Новости</h1>
          </RichText>
          <div style={{ maxWidth: 900 }}>
            <NewsList newsPosts={newsPosts} withDate />
          </div>
        </>
      )}
    </Segment>
  )
}

const GetPropertyWrapper = (props) => {
  const propertySerialNumber = props.match.params.propertySerialNumber
  const queryResult = useQuery('getProperty', {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        propertySerialNumber,
      },
    },
  })
  return (
    <PageWrapper queryResult={queryResult} checkExists={({ property }) => !!property}>
      {({ property, newsPosts, me }) => (
        <PropertyViewPage property={property} newsPosts={newsPosts} me={me} {...props} />
      )}
    </PageWrapper>
  )
}

export default GetPropertyWrapper
