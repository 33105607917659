export const getHomeRoute = () => '/'
export const getCreateDummyReferralRoute = () => '/new-referral'
export const getSignInRoute = () => '/sign-in'
export const getSignUpRoute = () => '/sign-up'
export const getConfirmEmailRoute = (confirmationToken) => `/confirm-email/${confirmationToken}`
export const getConfirmNewEmailRoute = (confirmNewEmailToken) => `/confirm-new-email/${confirmNewEmailToken}`
export const getResetPasswordRoute = (passwordResetToken) => `/reset-password/${passwordResetToken}`
export const getRestorePasswordRoute = () => '/restore-password'
export const getGenDocRoute = () => '/dashboard/gen-doc'
export const getEditProfileRoute = () => '/dashboard/edit-profile'
export const getSetupBotRoute = () => '/dashboard/setup-bot'
export const getReferralsRoute = () => '/dashboard/referrals'
export const getPayoutRequestsRoute = () => '/dashboard/payout-requests'
export const getCatalogRoute = () => '/dashboard/catalog'
export const getWelcomeRoute = () => '/dashboard/welcome'
export const getDashboardRoute = () => '/dashboard'
export const getUsersRoute = () => '/dashboard/users'
export const getUserCreateRoute = () => `/dashboard/users/create`
export const getUserRoute = (userSerialNumber) => `/dashboard/users/${userSerialNumber}`
export const getUserEditRoute = (userSerialNumber) => `/dashboard/users/${userSerialNumber}/edit`
export const getPassportsRoute = () => '/dashboard/passports'
export const getPassportRoute = (passportId) => `/dashboard/passports/${passportId}`
export const getBankAccountsRoute = () => '/dashboard/bank-accounts'
export const getBankAccountRoute = (bankAccountId) => `/dashboard/bank-accounts/${bankAccountId}`
export const getAllPropertiesRoute = () => '/dashboard/all-properties'
export const getNewsRoute = () => '/dashboard/news'
export const getNewsPublicRoute = () => '/news'
export const getPropertiesRoute = () => '/dashboard/properties'
export const getPropertyCreateRoute = () => `/dashboard/properties/create`
export const getPropertyRoute = (propertySerialNumber) => `/dashboard/properties/${propertySerialNumber}`
export const getPropertyEditRoute = (propertySerialNumber) => `/dashboard/properties/${propertySerialNumber}/edit`
export const getSyncSheetsRoute = () => '/dashboard/sync-sheets'
export const getSyncTbankrotRoute = () => '/dashboard/sync-tbankrot'
export const getFaqRoute = () => '/dashboard/faq'
export const getNewPaymentRoute = () => '/dashboard/new-payment'
export const getResultsRoute = () => '/results'
export const getNotionPropertiesRoute = () => '/properties'
export const getNotionFuturePropertiesRoute = () => '/future_properties'
export const getLastSignUpsAndTxsRoute = () => '/dashboard/last-sign-ups-and-txs-page'
export const getPublicPropertiesRoute = () => '/public/properties'
export const getDocsRoute = () => '/docs'
export const getDocsPolicyRoute = () => '/docs/policy'
export const getDocsTermsRoute = () => '/docs/terms'
export const getDocsContractRoute = () => '/docs/contract'

export const getCompanyResultsRoute = () => '/result'
export const getCompanyResultsAdminRoute = () => '/dashboard/results'
export const getCompanyResultCreateRoute = () => `/dashboard/results/create`
export const getCompanyResultEditRoute = (companyResultSerialNumber) =>
  `/dashboard/results/${companyResultSerialNumber}/edit`
