export const permissions = {
  useFutureFeatures: 'Может использовать функционал будущего',
  genDoc: 'Может генерировать документы',
  viewUsers: 'Может просматривать пользователей',
  editUsers: 'Может редактировать пользователей',
  editUserPermissions: 'Может редактировать права пользователей',
  deleteUsers: 'Может удалять пользователей',
  manageUserPassports: 'Может управлять паспортами пользователей',
  syncSheets: 'Может синхронизировать сервис с гугл таблицей',
  syncTbankrot: 'Может синхронизировать тбанкрот',
  viewLastSignUpsAndTxs: 'Может видеть последние транзакции и регистрации',
  manageProperties: 'Может управлять объектами',
  all: 'Может всё на свете',
}

const toHumanPermission = (permission) => {
  return permissions[permission] || `Что-то может, а что не понятно, но называется это ${permission}`
}

export default toHumanPermission
